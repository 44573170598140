<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" md="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                            <ValidationProvider name="Name (En)" vid="name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="name"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.name_en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="name"
                                                        v-model="fundSubHead.name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="name_bn"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.name_bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="name_bn"
                                                        v-model="fundSubHead.name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Amount" vid="amount" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="amount"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.amount') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="amount"
                                                        v-model="fundSubHead.amount"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Description (En)" vid="description" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="description"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.description_en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-textarea
                                                        id="description"
                                                        v-model="fundSubHead.description"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="description_bn"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.description_bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-textarea
                                                        id="description_bn"
                                                        v-model="fundSubHead.description_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-1">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { fundSubHeadStore, fundSubHeadUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            this.fundSubHead = this.fundSubHeadDetails()
        }
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id !== 0 ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            fundSubHead: {
                name: '',
                name_bn: '',
                amount: '',
                description: '',
                description_bn: ''
            },
            tmpData: {}
        }
    },
    mounted () {
        core.index()
    },
    methods: {
        fundSubHeadData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id !== 0) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${fundSubHeadUpdate}/${this.id}`, this.fundSubHead)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, fundSubHeadStore, this.fundSubHead)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        fundSubHeadDetails () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getRelationalData (data) {
            return data.map(item => {
                const fiscalYear = { fiscal_year_id: item.fiscal_year_id }
                const itemDetails = { details: item }
                return Object.assign({}, fiscalYear, itemDetails)
            })
        }
    }
}
</script>
